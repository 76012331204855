<template>
  <el-alert
    title="Excel内容按顺序输入到表单中，即第一列编号，第二列IMEI，第三列类型。。。之后如果有扩展的行，不做额外声明"
    style="margin: 20px 0;"
    type="info"
    effect="dark" />
  <el-alert
    title="类型对应 1:SML通用类型，在Excel中需要输入对应的ID值，如果要设置SML通用类型，那么应当填写1"
    style="margin: 20px 0;"
    type="info"
    effect="dark" />
  <div class="qr-tool">
    <el-form :inline="true" :model="formInline">
      <el-form-item label="编号">
        <el-input v-model="formInline.no" />
      </el-form-item>
      <el-form-item label="IMEI">
        <el-input v-model="formInline.imei" />
      </el-form-item>
      <el-form-item label="类型">
        <el-select v-model="formInline.machineTypeId" placeholder="请选择" clearable>
          <el-option v-for="item in machineTypes" :key="item.id" :value="item.id" :label="item.val" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">入录</el-button>
      </el-form-item>
      <el-form-item>
        <el-upload
          class="upload-demo"
          action="null"
          :before-upload="handleFile"
          :file-list="fileList"
          :show-file-list="false"
        >
          <el-button type="primary">导入EXCEL</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="upload">上传</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="qr-container">
    <el-card class="qr-card" shadow="hover" v-for="(machine, index) in machines" :key="machine.no">
      <template #header>
        <div class="clearfix">
          <span>编号：{{ machine.no }}</span>
          <el-button @click="onDel(index)" class="qr-action" type="text" icon="el-icon-delete" circle />
        </div>
      </template>
      <div>
        <span>IMEI: {{ machine.imei }}</span>
      </div>
      <div>
        <span>类型: {{ machineTypeNames[machine.machineTypeId] }}</span>
      </div>
      <div v-if="errors[machine.no]">
        <span class="text-danger">错误: {{ errors[machine.no] }}</span>
      </div>
    </el-card>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { handleCatchStatus } from '../../api/handler';

export default {
  data() {
    return {
      formInline: {
        no: null,
        imei: null
      },
      machines: [],
      fileList: [],
      machineTypes: [],
      machineTypeNames: {},
      errors: {}
    };
  },
  created() {
    this.$api.getMachineTypeMap().then(data => {
      this.machineTypes = data;
      data.forEach(item => {
        this.machineTypeNames[item.id] = item.val;
      });
    });
  },
  mounted() {
  },
  methods: {
    onSubmit() {
      this.push(this.formInline);
      this.formInline = {};
    },
    push({no, imei, machineTypeId = 1}) {
      this.machines.push({no, imei, machineTypeId});
    },
    onDel(index) {
      this.machines.splice(index, 1);
    },
    handleFile(file) {
      const self = this;
      const reader = new FileReader();
      reader.onload = function(e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {type: 'array'});
        const ws = workbook.Sheets[workbook.SheetNames[0]];
        const csv = XLSX.utils.sheet_to_csv(ws);
        console.log(csv);
        const machines = csv.split('\n')
          .map(item => item.split(',').filter(item => item !== ''))
          .map(list => ({no: list[0], imei: list[1], machineTypeId: list[2]}));
        console.log(machines);

        machines.forEach(element => {
          self.push(element);
        });
      };
      reader.readAsArrayBuffer(file);
      return false;
    },
    upload() {
      this.$api.createMachineBatch(this.machines).then(resp => {
        this.machines = resp.map(item => item.obj);
        resp.forEach(item => {
          this.errors[item.obj.no] = item.message;
        });

        if (resp.length === 0) {
          this.$message.success('操作成功!');
        } else {
          this.$message.warning('部分操作失败!');
        }
      })
        .catch(handleCatchStatus);
    }
  }
};
</script>

<style lang="scss" scoped>
.qr-container {
  display: flex;
  flex-wrap: wrap;
}

.qr-card {
  width: 300px;
  margin-right: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.qr-img {
  width: 100%;
}

.qr-action {
  float: right;
  padding: 3px 0;
  margin-left: 5px;
}
</style>
